enum MetricsType {
  DATASETS = 'Datasets',
  DOWNLOADS = 'Downloads',
  FILES = 'Files',
  LAUNCHES = 'Launches',
  PROJECTS = 'Projects',
  STORED = 'Stored',
  USERS = 'Users',
  LEADERBOARDS = 'Leaderboards',
}

export default MetricsType;
