import React, { useState } from 'react';
import { Box, Select, FormControl, MenuItem } from '@mui/material';
import styled from '@emotion/styled';
import MSDLeaderboard from './MSDLeaderboard';
import { useServices } from 'services';
import { toJS } from 'mobx';

const StyledFormControl = styled(FormControl)({
  position: 'absolute',
  top: '-150px',
  right: '15px',
});

enum LeaderBoardTypes {
  DOWNLOADS = 'Downloads',
  NOTEBOOK = 'Notebook',
}

const Leaderboard = (): React.ReactElement => {
  const [tableChoice, setTableChoice] = useState(LeaderBoardTypes.DOWNLOADS);

  const { metricsService } = useServices();

  const leaderboardTypes = [
    {
      leaderboardType: LeaderBoardTypes.DOWNLOADS,
      value: metricsService.downloadsLeaders,
    },
    {
      leaderboardType: LeaderBoardTypes.NOTEBOOK,
      value: metricsService.notebookLeaders,
    },
  ];

  const selectedLeaderboard = leaderboardTypes.find(
    (item) => item.leaderboardType === tableChoice
  );

  if (!selectedLeaderboard) {
    throw Error('selectedLeaderboard is not defined');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        gap: 2,
      }}
    >
      <StyledFormControl>
        <Select
          value={tableChoice}
          onChange={(event) =>
            setTableChoice(event.target.value as LeaderBoardTypes)
          }
          sx={{ width: 250 }}
        >
          <MenuItem value={LeaderBoardTypes.DOWNLOADS}>
            Total Downloads
          </MenuItem>
          <MenuItem value={LeaderBoardTypes.NOTEBOOK}>
            Total Notebook Launches
          </MenuItem>
        </Select>
      </StyledFormControl>
      <MSDLeaderboard
        tableChoice={tableChoice}
        leaderboardData={toJS(selectedLeaderboard.value)}
      />
    </Box>
  );
};

export default Leaderboard;
