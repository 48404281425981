import { makeObservable, observable, runInAction } from 'mobx';
import RdmApiService from './RdmApiService';
import GlobalErrorService from './GlobalErrorService';
import { AxiosError, AxiosResponse } from 'axios';
import { filesize } from 'filesize';

type SimpleDatapoint = { day: string; Value: number };
export type SimpleDataset = SimpleDatapoint[];

type ComplexDatapoint = { [x: string]: string | number };
export type ComplexDataset = ComplexDatapoint[];

type AnyDatapoint = SimpleDatapoint | ComplexDatapoint;
export type AnyDataset = AnyDatapoint[];

type PanelDatapoint = string | number | undefined;
type SimpleMetricData = { endpoint: string; summate: boolean };

export default class MetricsService {
  rdmApiService: RdmApiService;
  globalErrorService: GlobalErrorService;

  datasetsTime: ComplexDataset = [];
  // Keep in case we implement these downloads metrics
  // downloadsDatasets: ComplexDataset = [];
  // downloadsFiles: ComplexDataset = [];
  // downloadsSizes: ComplexDataset = [];
  downloadsUsers: ComplexDataset = [];
  downloadsOverall: ComplexDataset = [];

  bytesTime: SimpleDataset = [];
  filesTime: SimpleDataset = [];
  launchesTime: SimpleDataset = [];
  projectsTime: SimpleDataset = [];
  usersTime: SimpleDataset = [];

  bytesTotal: PanelDatapoint = undefined;
  downloadsOverallTotal: PanelDatapoint = undefined;
  downloadsUsersTotal: PanelDatapoint = undefined;
  filesTotal: PanelDatapoint = undefined;
  datasetsTotal: PanelDatapoint = undefined;
  launchesTotal: PanelDatapoint = undefined;
  projectsTotal: PanelDatapoint = undefined;
  usersTotal: PanelDatapoint = undefined;

  downloadsLeaders: {}[] = [];
  notebookLeaders: {}[] = [];

  constructor({
    rdmApiService,
    globalErrorService,
  }: {
    rdmApiService: RdmApiService;
    globalErrorService: GlobalErrorService;
  }) {
    this.rdmApiService = rdmApiService;
    this.globalErrorService = globalErrorService;

    makeObservable(this, {
      bytesTime: observable,
      bytesTotal: observable,
      datasetsTime: observable,
      datasetsTotal: observable,
      // Keep in case we implement these downloads metrics
      // downloadsDatasets: observable,
      // downloadsSizes: observable,
      // downloadsFiles: observable,
      downloadsUsers: observable,
      downloadsOverall: observable,
      downloadsOverallTotal: observable,
      downloadsUsersTotal: observable,
      filesTime: observable,
      filesTotal: observable,
      launchesTime: observable,
      launchesTotal: observable,
      projectsTime: observable,
      projectsTotal: observable,
      usersTime: observable,
      usersTotal: observable,
      downloadsLeaders: observable,
      notebookLeaders: observable,
    });
  }

  /** ==========
   *  Publicly called update function
   *  ==========
   */
  updateAllData = async (): Promise<void> => {
    this.getDownloadsMetrics();
    this.getComplexMetrics();
    this.getSimpleMetrics();
    this.getUploadsMetrics();
    this.getLeaderboardMetrics();
  };

  /** ==========
   *  DOWNLOADS METRICS
   *  (1) Response data is broken down by project
   *  (2) Returned data is also broken down by project
   *  (3) Returned data is NOT summed over time
   *  (4) Returned data always follows { [x: string]: ... } format
   *  (5) Total value is the sum of the values for:
   *      - Downloads from all projects
   *      - Across all days in the data
   *  ==========
   */
  getDownloadsMetrics = async (): Promise<void> => {
    this.getDownloadsPerDay();
    this.getUsersPerDay();
  };

  getOnlyProjectKeys = (datapoint: AnyDatapoint): string[] => {
    return Object.keys(datapoint).filter(
      (key) => key !== 'day' && key !== 'Value'
    );
  };

  generateMonthYearString = (date: Date): string => {
    return `${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
  };

  // TODO (possible) - groupComplexDatasetByMonth - rename to this, generalize the function
  // Kept this function in case we want to use it in the future so we don't have to entirely rewrite it
  // groupDownloadsMetricPerMonth = (data: ComplexDataset): ComplexDataset => {
  //   const newData: ComplexDataset = [];
  //   const firstIndexDate = new Date(data[0].day as string);
  //   const firstIndexMonth = firstIndexDate.getUTCMonth();
  //   let currentMonthNum = firstIndexMonth;
  //   let currentMonthData: ComplexDatapoint = {
  //     day: this.generateMonthYearString(firstIndexDate),
  //   };
  //   data.forEach((datapoint: ComplexDatapoint, index: number) => {
  //     const date = new Date(datapoint.day);
  //     const datapointMonth = date.getUTCMonth();
  //     const datapointInNewMonth = datapointMonth !== currentMonthNum;

  //     if (datapointInNewMonth) {
  //       newData.push(currentMonthData);
  //       currentMonthNum = date.getUTCMonth();
  //       currentMonthData = { day: this.generateMonthYearString(date) };
  //     }

  //     const currentMonthProjects = this.getOnlyProjectKeys(currentMonthData);
  //     const datapointProjects = this.getOnlyProjectKeys(datapoint);
  //     datapointProjects.forEach((datapointProject: string) => {
  //       const datapointProjectInCurrentMonth =
  //         currentMonthProjects.includes(datapointProject);
  //       if (datapointProjectInCurrentMonth) {
  //         (currentMonthData[datapointProject] as number) += datapoint[
  //           datapointProject
  //         ] as number;
  //       } else {
  //         (currentMonthData[datapointProject] as number) = datapoint[
  //           datapointProject
  //         ] as number;
  //       }
  //     });

  //     const atLastIndex = index === data.length - 1;
  //     if (atLastIndex) {
  //       newData.push(currentMonthData);
  //     }
  //   });

  //   return newData;
  // };

  getDownloadsPerDay = async (): Promise<void> => {
    try {
      const response = await this.rdmApiService.axios.get(
        '/metrics/downloads/unique'
      );
      const total = this.getDownloadsResponseDataTotal(
        response.data.downloadCount
      );

      const data = response.data.downloadCount;
      const finalData = this.appendMissingDates(data, false);

      runInAction(() => {
        this.downloadsOverall = finalData;
        this.downloadsOverallTotal = total;
      });
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  getUsersPerDay = async (): Promise<void> => {
    try {
      const response = await this.rdmApiService.axios.get('/metrics/downloads');
      const total = this.getDownloadsResponseDataTotal(
        response.data.uniqueUsers
      );

      const data = response.data.uniqueUsers;
      const finalData = this.appendMissingDates(data, false);

      runInAction(() => {
        this.downloadsUsers = finalData;
        this.downloadsUsersTotal = total;
      });
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  getDownloadsResponseDataTotal = (data: AxiosResponse['data']): number => {
    let runningTotal = 0;
    data.forEach((datapoint: ComplexDatapoint) => {
      const keys = Object.keys(datapoint).filter((key) => key !== 'day');
      keys.forEach((key: string) => {
        runningTotal += datapoint[key] as number;
      });
    });
    return runningTotal;
  };

  /** ==========
   *  COMPLEX METRICS
   *  (1) Response data from API IS broken down by project
   *  (2) Returned data is summed over time per project
   *  (3) Returned data always follows { [x: string]: ... } format
   *  (4) Total value is the sum from all projects at the final index
   *
   *  Currently gets: Datasets
   *  ==========
   */
  getComplexMetrics = async (): Promise<void> => {
    try {
      const response = await this.rdmApiService.axios.get(
        '/metrics/counts/datasets'
      );
      const data = this.sumDatasetsOverTime(response.data);
      const total = this.getDatasetsTotal(data);
      const dataWithMissingDates = this.appendMissingDates(data, true);
      const finalData = this.groupDatasetsByMonth(dataWithMissingDates);

      runInAction(() => {
        this.datasetsTime = finalData;
        this.datasetsTotal = total;
      });
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  sumDatasetsOverTime = (data: ComplexDataset): ComplexDataset => {
    const allFoundProjects: string[] = [];
    data.forEach((datapoint: ComplexDatapoint, idx: number) => {
      const atFirstDatapoint = idx === 0;
      const datapointProjects = Object.keys(datapoint).filter((project) => {
        return project !== 'day';
      });
      datapointProjects.forEach((project: string) => {
        const projectAlreadyFound = allFoundProjects.includes(project);
        if (!projectAlreadyFound) {
          allFoundProjects.push(project);
        }
      });
      if (!atFirstDatapoint) {
        allFoundProjects.forEach((project: string) => {
          const currentProjectValue = (data[idx][project] as number) || 0;
          const peviousProjectValue = (data[idx - 1][project] as number) || 0;
          data[idx][project] = currentProjectValue + peviousProjectValue;
        });
      }
    });
    return data;
  };

  getDatasetsTotal = (data: ComplexDataset): number => {
    let runningTotal = 0;
    const lastDatapoint = data[data.length - 1];
    if (!lastDatapoint) return 0;
    Object.keys(lastDatapoint).forEach((key: string) => {
      if (key !== 'day') {
        runningTotal += lastDatapoint[key] as number;
      }
    });
    return runningTotal;
  };

  groupDatasetsByMonth = (data: AnyDataset): AnyDataset => {
    const monthData: AnyDataset = [];
    data.forEach((datapoint: AnyDatapoint, index: number) => {
      const atLastIndex = index === data.length - 1;
      const date = new Date(datapoint.day);
      if (atLastIndex || this.isLastDayOfMonth(date)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [month, day, year] = (datapoint.day as string).split('/');
        const datestringWithoutDay = `${month}/20${year}`;
        monthData.push({ ...datapoint, day: datestringWithoutDay });
      }
    });
    return monthData;
  };

  /** ==========
   *  SIMPLE METRICS
   *  (1) Response data from API is NOT broken by project,
   *  (2) Can either be summed over time (i.e. Projects, Users) or not (i.e. Launches)
   *  (3) The data returned follows a simple { day: <date>, Value: <number> } format
   *  (4) Total value is:
   *      - If data is summed over time, the data's value at the last index
   *      - If not summed over time, the sum of values at every index
   *
   *  Current metrics: Launches, Projects, Users
   *  ==========
   */
  getSimpleMetrics = async (): Promise<void> => {
    const simpleMetricsData: SimpleMetricData[] = [
      { endpoint: '/metrics/notebooks', summate: false },
      { endpoint: '/metrics/counts/projects', summate: true },
      { endpoint: '/metrics/counts/users', summate: true },
    ];
    simpleMetricsData.forEach((data: SimpleMetricData) => {
      this.getSimpleMetric(data.endpoint, data.summate);
    });
  };

  getSimpleMetric = async (
    endpoint: string,
    summate: boolean
  ): Promise<void> => {
    try {
      const response = await this.rdmApiService.axios.get(endpoint);
      const [data, total] = this.aggregateSimpleResponseData(
        response.data,
        summate
      );

      switch (endpoint) {
        case '/metrics/notebooks':
          const finalNotebooksData = this.appendMissingDates(data, false);
          runInAction(() => {
            this.launchesTime = finalNotebooksData;
            this.launchesTotal = total;
          });
          break;
        case '/metrics/counts/projects':
          const finalProjectsData = this.appendMissingDates(data, true);
          runInAction(() => {
            this.projectsTime = finalProjectsData;
            this.projectsTotal = total;
          });
          break;
        case '/metrics/counts/users':
          const finalUsersData = this.appendMissingDates(data, true);
          runInAction(() => {
            this.usersTime = finalUsersData;
            this.usersTotal = total;
          });
          break;
      }
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  aggregateSimpleResponseData = (
    data: AxiosResponse['data'],
    summate: boolean
  ): [SimpleDataset, number] => {
    let runningTotal: number = 0;
    data.forEach((datapoint: SimpleDatapoint, idx: number) => {
      runningTotal += datapoint.Value;
      if (summate) data[idx].Value = runningTotal;
    });
    return [data, runningTotal];
  };

  /** ==========
   *  UPLOADS METRICS
   *  (1) Response data from API IS broken down by project
   *  (2) Response data received from /metrics/uploads endpoint
   *  (3) Returned data summed over time as a whole, despite data being broken down
   *  (4) Total values are the value at the last index of returned data (i.e. the sum)
   *
   *  Currently gets: Files, Stored
   *  ==========
   */
  getUploadsMetrics = async (): Promise<void> => {
    try {
      const endpoint = '/metrics/uploads';
      const response = await this.rdmApiService.axios.get(endpoint);

      const [bytesTime, bytesTotal] = this.getUploadsMetric(
        response.data,
        'totalbytes'
      );
      const finalBytesData = this.appendMissingDates(bytesTime, true);

      const formattedBytesTotal = filesize(bytesTotal, {
        round: 1,
        roundingMethod: 'floor',
        standard: 'iec',
      }).replace(/[a-z]/g, '');
      // Replace all lowercase chars; do not want "GiB", replace with "GB"

      const [filesTime, filesTotal] = this.getUploadsMetric(
        response.data,
        'uniqueFiles'
      );
      const finalFilesData = this.appendMissingDates(filesTime, true);

      const formattedFilesTotal = Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
      })
        .format(filesTotal)
        .toLowerCase();

      runInAction(() => {
        this.bytesTime = finalBytesData;
        this.bytesTotal = formattedBytesTotal;
        this.filesTime = finalFilesData;
        this.filesTotal = formattedFilesTotal;
      });
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  getUploadsMetric = (
    data: AxiosResponse['data'],
    property: string
  ): [SimpleDataset, number] => {
    let runningTotal = 0;
    const metricData = data[property];
    metricData.forEach((datapoint: ComplexDatapoint) => {
      let datapointTotal = 0;
      const keys = Object.keys(datapoint).filter((key) => key !== 'day');
      keys.forEach((key: string) => {
        datapointTotal += datapoint[key] as number;
        delete datapoint[key];
        // Delete key so original data is converted from { [x: string], ... } to { day: string, Value: number }
      });
      runningTotal += datapointTotal;
      datapoint.Value = runningTotal; // Append as "Value" key after all keys have been deleted
    });
    return [metricData, runningTotal];
  };

  /** ==========
   *  LEADERBOARD METRICS
   *  ==========
   */

  getLeaderboardMetrics = async (): Promise<void> => {
    this.getDownloadsLeaders();
    this.getNotebookLeaders();
  };

  getDownloadsLeaders = async (): Promise<void> => {
    try {
      const response = await this.rdmApiService.axios.get("/metrics/downloads/leader-board")
      const data = response.data;
      runInAction(() => {
        this.downloadsLeaders = data;
      });
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  getNotebookLeaders = async (): Promise<void> => {
    try {
      const response = await this.rdmApiService.axios.get("/metrics/notebooks/leader-board")
      const data = response.data;
      runInAction(() => {
        this.notebookLeaders = data;
      });
    } catch (e: unknown) {
      this.displayError(e);
    }
  };

  /** ==========
   *  UTILITIES
   *  ==========
   */

  displayError = (e: unknown): void => {
    if (e instanceof AxiosError) {
      this.globalErrorService.showError({
        message: (e as AxiosError).message,
      });
    } else {
      throw e;
    }
  };

  appendMissingDates = <T extends AnyDataset>(
    data: T,
    appendPreviousData: boolean
  ): T => {
    const newData: T = [] as unknown as T;
    data.forEach((datapoint: AnyDatapoint, index: number) => {
      const atLastIndex = index === data.length - 1;
      if (!atLastIndex) {
        newData.push(datapoint);
        const nextDatapoint = data[index + 1];

        const currentDate = new Date(datapoint.day);
        const nextDate = new Date(nextDatapoint.day);

        const currentDateEpoch = currentDate.getTime();
        const nextDateEpoch = nextDate.getTime();

        const daysBetween = (nextDateEpoch - currentDateEpoch) / 86400000;

        for (let i = 1; i < daysBetween; i++) {
          let interimDate = new Date(currentDate);
          interimDate.setDate(interimDate.getDate() + i);

          const year = interimDate.getFullYear().toString();
          const month = (interimDate.getMonth() + 1).toString();
          const day = interimDate.getDate().toString();

          const shortYear = year.substring(2);
          const formattedMonth = month.length === 1 ? `0${month}` : month;
          const formattedDay = day.length === 1 ? `0${day}` : day;

          const shorthandDate = `${formattedMonth}/${formattedDay}/${shortYear}`;

          newData.push(
            appendPreviousData
              ? { ...datapoint, day: shorthandDate }
              : { Value: 0, day: shorthandDate }
          );
        }
      } else {
        newData.push(datapoint);
      }
    });
    return newData;
  };

  isFirstDayOfMonth = (date: Date): boolean => {
    return date.getUTCDate() === 1;
  };

  isLastDayOfMonth = (date: Date): boolean => {
    const currentDateMonth = date.getUTCMonth();
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    const nextDateMonth = nextDate.getUTCMonth();
    return currentDateMonth !== nextDateMonth;
  };
}
