import { useState } from 'react';
import MSDBarChart from './MSDBarChart';
import styled from '@emotion/styled';
import { useServices } from 'services';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import DownloadsPanelLabel from './DownloadsPanelLabel';

const Root = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 50px;
  position: relative;
  .MuiFormControlLabel-label {
    margin: 0;
  }
  .css-1agxaco-MuiFormLabel-root.Mui-focused {
    color: black;
  }
`;

const StyledFormControl = styled(FormControl)({
  position: 'absolute',
  color: 'black',
  top: '-34px',
  left: '72px',
});

interface DownloadsPanelProps {
  className?: string;
}

enum DownloadTypes {
  DOWNLOADS = 'Downloads',
  USERS = 'Users',
  VOLUME = 'Volume',
  DOWNLOADED = 'Downloaded',
}

function DownloadsPanel(props: DownloadsPanelProps): React.ReactElement {
  const { metricsService } = useServices();

  const types = [
    {
      downloadType: DownloadTypes.DOWNLOADS,
      description: `A download is defined as one or more file downloads from a dataset in a single session.`,
      tooltipName: 'Download',
      tooltipFormatter: false,
      filesizeFormat: false,
      value: metricsService.downloadsOverall,
      stacked: true,
    },
    {
      downloadType: DownloadTypes.USERS,
      description: `Total unique users who downloaded a file that day`,
      tooltipName: 'User',
      tooltipFormatter: false,
      filesizeFormat: false,
      value: metricsService.downloadsUsers,
      stacked: true,
    },
  ];

  const [downloadsVariable, setDownloadsVariable] = useState(
    DownloadTypes.DOWNLOADS
  );

  const selectedType = types.find(
    (item) => item.downloadType === downloadsVariable
  );

  if (!selectedType) {
    throw Error('selectedType is not defined');
  }

  const getTotal = (
    downloadType: DownloadTypes
  ): string | number | undefined => {
    let returnValue;
    switch (downloadType) {
      case DownloadTypes.DOWNLOADS:
        returnValue = metricsService.downloadsOverallTotal;
        break;
      case DownloadTypes.USERS:
        returnValue = metricsService.downloadsUsersTotal;
        break;
      default:
        returnValue = undefined;
    }
    return returnValue;
  };

  return (
    <Root>
      <StyledFormControl>
        <RadioGroup
          aria-labelledby="downloads-radio"
          name="download-radio-buttons"
          value={downloadsVariable}
          onChange={(_, value) => setDownloadsVariable(value as DownloadTypes)}
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row',
            gap: '1rem',
          }}
        >
          <FormControlLabel
            value={DownloadTypes.DOWNLOADS}
            control={<Radio />}
            label={
              <DownloadsPanelLabel
                text="Downloads per Day"
                description={types[0].description}
              />
            }
          />
          <FormControlLabel
            value={DownloadTypes.USERS}
            control={<Radio />}
            label={
              <DownloadsPanelLabel
                text="Users per Day"
                description={types[1].description}
              />
            }
          /> 
        </RadioGroup>
      </StyledFormControl>

      <MSDBarChart
        data={selectedType.value}
        total={getTotal(downloadsVariable)}
        metricType={downloadsVariable}
        tooltipVar={selectedType.tooltipName}
        tooltipFormatter={selectedType.tooltipFormatter}
        filesizeFormat={selectedType.filesizeFormat}
        chartLabelOffsetRight={62}
        stacked={selectedType.stacked}
      />
    </Root>
  );
}

export default observer(DownloadsPanel);
