// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useServices } from 'services';
import MSDBarChart from './MSDBarChart';
import DownloadsPanel from './DownloadsPanel';
import MSDLineChart from './MSDLineChart';
import DetailsPanelError from './DetailsPanelError';
import MetricsType from 'types/MetricsType';
import LeaderboardPanel from './LeaderboardPanel';

export interface DetailsPanelPresenterProps {
  metric: string;
  className?: string;
}

function DetailsPanelPresenter(
  props: DetailsPanelPresenterProps
): React.ReactElement | null {
  const { metric } = props;
  const { metricsService } = useServices();

  switch (metric) {
    case MetricsType.DATASETS:
      return (
        <MSDBarChart
          data={metricsService.datasetsTime}
          total={metricsService.datasetsTotal}
          metricType={metric}
          tooltipVar="Dataset"
          stacked
        />
      );
    case MetricsType.DOWNLOADS:
      return <DownloadsPanel />;
    case MetricsType.FILES:
      return (
        <MSDLineChart
          data={metricsService.filesTime}
          total={metricsService.filesTotal}
          metricType={metric}
          tooltipVar="File"
          showBrush={false}
        />
      );
    case MetricsType.LAUNCHES:
      return (
        <MSDLineChart
          data={metricsService.launchesTime}
          total={metricsService.launchesTotal}
          metricType={metric}
          tooltipVar="Launch"
          showBrush={true}
        />
      );
    case MetricsType.PROJECTS:
      return (
        <MSDLineChart
          data={metricsService.projectsTime}
          total={metricsService.projectsTotal}
          metricType={metric}
          tooltipVar="Project"
          showBrush={false}
        />
      );
    case MetricsType.STORED:
      return (
        <MSDLineChart
          data={metricsService.bytesTime}
          total={metricsService.bytesTotal}
          metricType={metric}
          showBrush={false}
          tooltipVar="" // intentionally left blank
          tooltipFormatter
          filesizeFormat
        />
      );
    case MetricsType.USERS:
      return (
        <MSDLineChart
          data={metricsService.usersTime}
          total={metricsService.usersTotal}
          metricType={metric}
          showBrush={false}
          tooltipVar="User"
        />
      );
    case MetricsType.LEADERBOARDS:
      return <LeaderboardPanel />;
    default:
      return <DetailsPanelError metric={metric} />;
  }
}

export default observer(DetailsPanelPresenter);
