import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useServices } from 'services';
import Project from 'types/Project';

export interface MSDLeaderboardProps {
  leaderboardData: Array<{}>;
  tableChoice: string;
}

const MSDLeaderboard = (props: MSDLeaderboardProps): React.ReactElement => {
  const { leaderboardData, tableChoice } = props;

  const { projectService, envService } = useServices();
  const [projectNames, setProjectNames] = useState<Record<string, string>>({});

  useEffect(() => {
    projectService.search();
  }, [projectService]);

  const projects = projectService.projects;
  const projectKeys = Object.keys(projects);
  const projectsExist = projectKeys.length > 0;

  const projectNameKeys = Object.keys(projectNames);
  const projectNamesExist = projectNameKeys.length > 0;

  useEffect(() => {
    if (projectsExist && !projectNamesExist) {
      const newProjectNames: Record<string, string> = {};
      projectKeys.forEach((key: string) => {
        const project = projects[key];
        if (project instanceof Project) {
          newProjectNames[key] = project.title;
        } else {
          // Do not do any translation
          newProjectNames[key] = key;
        }
      });
      setProjectNames(newProjectNames);
    }
  }, [projectsExist, projectNamesExist, projectKeys, projects]);

  let statsLabel = 'Downloads';
  let nameLabel = 'Dataset';
  let indexMap = {
    record: 0,
    project: 2,
    name: 1,
    stats: 3,
  };

  switch (tableChoice) {
    case 'Downloads':
      nameLabel = 'Dataset';
      statsLabel = 'Total Downloads';
      indexMap = {
        record: 0,
        project: 2,
        name: 1,
        stats: 3,
      };
      break;
    case 'Notebook':
      nameLabel = 'Notebook';
      statsLabel = 'Notebook Launches';
      indexMap = {
        record: 0,
        project: 1,
        name: 0,
        stats: 2,
      };
      break;
  }

  const getProjectName = (projectDataName: string): string => {
    const projectName =
      projectDataName.toString().replace('msdlive-project-', '') || '';
    const nameInProjectMap = projectNameKeys.includes(projectName);
    const displayName = nameInProjectMap
      ? projectNames[projectName]
      : projectName;

    return displayName;
  };

  const getLink = (itemInfo: string, tableChoice: string): string => {
    let baseUrl = envService.getValue('DATA_REPO_URL');
    let navLink;

    if (tableChoice === 'Downloads') {
      navLink = `${baseUrl}/records/${itemInfo}`;
    } else {
      navLink = `https://${itemInfo}.msdlive.org`;
    }

    return navLink;
  };

  const isLeaderboardDataArray =
    Array.isArray(leaderboardData) && leaderboardData.length > 0;

  return (
    <>
      {isLeaderboardDataArray ? (
        <Box sx={{ display: 'flex', borderRadius: 1, width: 1200, border: 1 }}>
          <TableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography>Rank</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>Project</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingLeft: 7 }}>
                    <Typography>{nameLabel}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{statsLabel}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaderboardData.map((item: {}, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor:
                        index % 2 === 1 ? '#e0e0e0' : 'transparent',
                    }}
                  >
                    <TableCell align="center">
                      <Typography>{index + 1}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {getProjectName(
                          String(Object.values(item)[indexMap.project])
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link
                        href={getLink(
                          String(Object.values(item)[indexMap.record]),
                          tableChoice
                        )}
                      >
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 600,
                            paddingLeft: 5,
                          }}
                        >
                          {String(Object.values(item)[indexMap.name])}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      <Typography>
                        {String(Object.values(item)[indexMap.stats])}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <div>
          <p style={{ color: '#000000' }}>No Leaderboard Data Available</p>
        </div>
      )}
    </>
  );
};

export default MSDLeaderboard;
