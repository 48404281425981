import React, { useEffect, useState } from 'react';
import { icons } from './utils';
import { useServices } from 'services';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import {
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import DetailsPanelPresenter from './DetailsPanelPresenter';
import MetricsType from 'types/MetricsType';
import H1 from 'components/core/H1';
import PageContent from 'components/core/PageContent';

const Root = styled(PageContent)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const Panel = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '100px',
  marginBottom: '100px',
});

const DetailsPanel = styled.div({
  width: '1092px',
  height: '290px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#E0E1E2',
  fontSize: '20px',
});

const GridContainer = styled(ToggleButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 1.8rem;
  width: 100%;
  @media (max-width: 1400px) {
    gap: 0 1.5rem;
  }
  @media (max-width: 1115px) {
    gap: 0 1.3rem;
  }
`;

const GridItem = styled(ToggleButton)`
  &&& {
    height: 5rem;
    width: 22%;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid black;
    text-transform: capitalize; // No uppercase var names
    &:nth-of-type(odd):last-child {
      padding-bottom: '1.5rem';
    }
    @media (max-width: 1400px) {
      width: 32%;
    }
    @media (max-width: 1115px) {
      width: 49%;
    }
    @media (max-width: 965px) {
      width: 100%;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)(({ theme: { palette } }) => ({
  color: palette.primary.dark,
  marginRight: '4px',
  width: '56px',
  display: 'flex',
}));

const SVGIcon = styled.img({
  marginRight: '8px',
  height: '44px',
  width: '44px',
});

const GridItemText = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '8px',
});

const DataFont = styled.div({
  fontWeight: '700',
  lineHeight: '1.15',
  fontFamily: "'Rubik', sans-serif",
});

const MetricDataFont = styled(DataFont)({
  fontSize: '28px',
  color: '#16adfe',
  width: 'max-content',
});

const MetricNameFont = styled(DataFont)({
  fontSize: '24px',
  marginBottom: '2px',
  color: 'black',
});

const StyledSpinner = styled(CircularProgress)({
  color: '#16adfe',
  marginRight: '8px',
});

interface MetricsPageProps {
  className?: string;
}

function MetricsPage(props: MetricsPageProps): React.ReactElement | null {
  const { className } = props;
  const { metricsService } = useServices();

  const [selectedTitle, setSelectedTitle] = useState('Users');
  const [selectedMetric, setSelectedMetric] = useState<MetricsType>(
    MetricsType.USERS
  );

  useEffect(() => {
    metricsService.updateAllData();
    setSelectedTitle(selectedMetric);
  }, [metricsService, selectedMetric]);

  const metrics = [
    {
      faIcon: icons.faUser,
      data: metricsService.usersTotal,
      displayName: 'Users',
      metricType: MetricsType.USERS,
    },
    {
      faIcon: icons.faFolderOpen,
      data: metricsService.projectsTotal,
      displayName: 'Projects',
      metricType: MetricsType.PROJECTS,
    },
    {
      faIcon: icons.faChartColumn,
      data: metricsService.datasetsTotal,
      displayName: 'Datasets',
      metricType: MetricsType.DATASETS,
    },
    {
      faIcon: icons.faFile,
      data: metricsService.filesTotal,
      displayName: 'Files',
      metricType: MetricsType.FILES,
    },
    {
      faIcon: icons.faDatabase,
      data: metricsService.bytesTotal,
      displayName: 'Stored',
      metricType: MetricsType.STORED,
    },
    {
      faIcon: icons.faDownload,
      data: null,
      displayName: 'Downloads',
      metricType: MetricsType.DOWNLOADS,
    },
    {
      icon: <SVGIcon src={icons.jupyterLogo} />,
      data: metricsService.launchesTotal,
      displayName: 'Launches',
      metricType: MetricsType.LAUNCHES,
    },
    {
      faIcon: icons.faTrophy,
      data: null,
      displayName: 'Leaderboards',
      metricType: MetricsType.LEADERBOARDS,
    },
  ];

  let detailsPanelContent;
  if (selectedMetric) {
    detailsPanelContent = (
      <DetailsPanelPresenter
        metric={selectedMetric}
        key={'selectedMetric-' + selectedMetric}
        // Key provided so graph fully rerenders each time
      />
    );
  } else {
    detailsPanelContent = (
      <div>Click on a panel below to see details here...</div>
    );
  }

  const theme = useTheme();

  return (
    <Root className={className} maxWidth={theme.maxToolWidth}>
      <H1>MSD-LIVE Metrics | {selectedTitle}</H1>
      <Panel>
        <DetailsPanel>{detailsPanelContent}</DetailsPanel>
      </Panel>
      <GridContainer
        value={selectedMetric}
        onChange={(_, value) => {
          value !== null && setSelectedMetric(value);
        }}
        exclusive
      >
        {metrics.map((metric, idx) => {
          const spinnerOrData =
            metric.data === undefined ? (
              <StyledSpinner size="2rem" />
            ) : (
              metric.data
            );

          let icon: React.ReactNode;
          if (metric.faIcon) {
            icon = <Icon icon={metric.faIcon} size="3x" />;
          } else if (metric.icon) {
            icon = metric.icon as React.ReactElement;
          } else {
            throw Error('Must have an icon or faIcon');
          }

          return (
            <GridItem
              value={metric.metricType}
              key={`gridItem${idx}`}
              aria-label={metric.displayName + ' metric'}
            >
              {icon}
              <GridItemText>
                {spinnerOrData !== null && (
                  <MetricDataFont>{spinnerOrData}</MetricDataFont>
                )}
                <MetricNameFont>{metric.displayName}</MetricNameFont>
              </GridItemText>
            </GridItem>
          );
        })}
      </GridContainer>
    </Root>
  );
}

export default observer(MetricsPage);
