import {
  faChartColumn,
  faDatabase,
  faDownload,
  faEye,
  faFile,
  faFolderOpen,
  faUser,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import jupyterLogo from 'resources/jupyter_logo.svg';
import { AnyDataset } from 'services/MetricsService';

export const maxHeight = 290;
export const maxWidth = 1248; // change this based on screen width when/if implementing mobile
export const usualMargins: Record<string, number> = {
  top: 0,
  right: 60,
  left: 0,
  bottom: 0,
};

export const icons = {
  faChartColumn: faChartColumn,
  faDatabase: faDatabase,
  faDownload: faDownload,
  faEye: faEye,
  faFile: faFile,
  faFolderOpen: faFolderOpen,
  faUser: faUser,
  faCircleQuestion: faCircleQuestion,
  jupyterLogo: jupyterLogo,
  faTrophy: faTrophy,
};

export function changeMargins(
  inputMargins: Record<string, number>
): Record<string, number> {
  const returnMargins: Record<string, number> = {};
  Object.keys(usualMargins).map((key) => {
    if (inputMargins[key]) {
      return (returnMargins[key] = usualMargins[key] + inputMargins[key]);
    } else {
      return (returnMargins[key] = usualMargins[key]);
    }
  });
  return returnMargins;
}

export function getCurrKeys(
  data: { [x: string]: string | number }[]
): string[] {
  const allKeys = new Set<string>();

  data.forEach((item) => {
    const currKeys = Object.keys(item);

    currKeys.forEach((key) => {
      allKeys.add(key);
    });
  });

  allKeys.delete('day');
  return Array.from(allKeys);
}

export function getColors(): string[] {
  return ['#093e80', '#1756a7', '#2f71c0', '#2290da', '#16adfe'];
}

export const BASE_NUM_TICKS_ALLOWED = 8;

export const generateNumTicksAllowed = (
  dataLength: number,
  brushWidth: number
): number => {
  return Math.floor(
    dataLength / Math.ceil(brushWidth / BASE_NUM_TICKS_ALLOWED)
  );
};

export const generateTickIndicies = (
  numDataEntries: number,
  numTicksAllowed: number = BASE_NUM_TICKS_ALLOWED
): number[] => {
  const tickIndicies: number[] = [];
  const maxIndex = numDataEntries - 1;

  if (numDataEntries <= numTicksAllowed) {
    for (let i = 0; i <= maxIndex; i++) tickIndicies.push(i);
    return tickIndicies;
  }

  // Always includes a tick at the last index; floors so all numbers are round numbers
  const stepInterval = Math.floor(maxIndex / (numTicksAllowed - 1));
  for (
    let currentIndex = maxIndex;
    currentIndex > 0;
    currentIndex = currentIndex - stepInterval
  ) {
    tickIndicies.unshift(currentIndex);
  }
  return tickIndicies;
};

//https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const nth = (n: number): string => {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
};

export const generateTickData = (
  data: AnyDataset,
  tickIndicies: number[]
): string[] => {
  const tickData: string[] = [];

  tickIndicies.forEach((index: number) => {
    tickData.push(data[index].day as string);
  });

  return tickData;
};
